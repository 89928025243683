import Listener from '../../helpers/listener-once';


export default class CustomFilters {
    constructor() {
        this.indexBase = 25;
        this.indexCurrent = this.indexBase;
    }

    init() {
        this.initStack();

        prestashop.on('updateProductList', (data) => {
            // necessary
            setTimeout(() => {
                this.initStack();
            })
        });
    }

    initStack() {
        var arkon_react_to_criterion_change = false; // external

        this.updateFilters();

        this.initValueChanged();

        this.initCustomCheckboxes();
        this.initWideCheckboxes();

        this.initShowFilters();
        this.initHideFilters();

        this.initActiveFilters();

        this.initResetGroup();
        this.initShowResults();

        this.initResetFilters();
    }

    initValueChanged() {
        this.valueChanged = false;

        const inputs = document.querySelectorAll('input[type=checkbox]');
        for (const input of inputs) {
            input.addEventListener('change', () => {
                this.valueChanged = true;
            });
        }
    }

    initShowFilters() {
        const showFilters = document.querySelectorAll('.js-custom-filters-show, .filters-show');

        if (!showFilters) {
            return;
        }

        for (const showFilter of showFilters) {
            showFilter.addEventListener('click', () => {
                this.showFilters();
            });
        }
    }

    initHideFilters() {
        const hideFilters = document.querySelectorAll('.js-custom-filters-hide, .filters-close');

        if (!hideFilters) {
            return;
        }

        for (const hideFilter of hideFilters) {
            hideFilter.addEventListener('click', () => {
                this.hideFilters();
            });
        }
    }

    // also partially inits wide-checkboxes
    initCustomCheckboxes() {
        const customCheckboxes = document.querySelectorAll('.custom-filters .PM_ASCriterionsGroup');

        for (const checkbox of customCheckboxes) {
            this.initCustomCheckbox(checkbox);
        }
    }

    initCustomCheckbox(checkbox) {
        if (!checkbox) {
            return;
        }

        const checkboxList = checkbox.querySelector('.PM_ASCriterionsOutput');
        if (checkboxList) {
            checkboxList.style.zIndex = this.indexBase;
        }

        const checkboxHeader = checkbox.querySelector('.PM_ASCriterionsGroupTitle');

        Listener.addOnce(checkboxHeader, 'click', () => {
            this.onCustomCheckboxHeaderClick(checkbox);
        }, 'headerclick');
    }

    onCustomCheckboxHeaderClick(checkbox) {
        if (checkbox.classList.contains('active')) {
            checkbox.classList.remove('active');
        } else {
            this.hideAllGroups();
            checkbox.classList.toggle('active');
        }

        if (this.valueChanged) {
            this.submitFilters();
        }

        const checkboxList = checkbox.querySelector('.PM_ASCriterionsOutput');

        if (checkboxList) {
            checkboxList.style.zIndex = ++this.indexCurrent;
        }
    }

    initWideCheckboxes() {
        const wideCheckboxes = document.querySelectorAll('.custom-filters .PM_ASCriterionsGroup.checkbox-wide');

        for (const checkbox of wideCheckboxes) {
            this.initWideCheckbox(checkbox);
        }
    }

    initWideCheckbox(checkbox) {
        const inputs = checkbox.querySelectorAll('input');

        Listener.addOnce(checkbox, 'click', () => {
            this.onWideCheckboxClick();
        }, 'widecheckboxclick');

        for (const input of inputs) {
            Listener.addOnce(input, 'change', () => {
                this.onWideCheckboxInputChange(checkbox);
            }, 'widecheckboxchange');
        }
    }

    onWideCheckboxClick() {
        const activeCheckboxes = document.querySelectorAll('.checkbox-custom.active');

        if (activeCheckboxes.length) {
            this.hideAllGroups();

            if (this.valueChanged) {
                this.submitFilters();
            }
        }
    }

    onWideCheckboxInputChange(checkbox) {
        const selectFilters = checkbox.querySelector('.js-filters-select');

        if (selectFilters) {
            selectFilters.style.display = "block";
        }
    }

    initActiveFilters() {
        let resultHTML = '';

        // active + main filters joined by selector
        const activeFilters = document.querySelectorAll('.PM_ASCriterionsGroup.checked, .PM_ASCriterionsGroup.custom-filter-main');

        for (const filter of activeFilters) {
            resultHTML += this.getActiveFilterTemplate(filter);
        }

        const activeFiltersContainer = document.querySelector('.js-custom-filters-active');
        if (resultHTML && activeFiltersContainer) {
            activeFiltersContainer.innerHTML = resultHTML;
        }

        this.initActiveFiltersShow();
    }

    initActiveFiltersShow() {
        const activeFilters = document.querySelectorAll('.js-active-filter');

        for (const activeFilter of activeFilters) {
            Listener.addOnce(activeFilter, 'click', () => {
                this.onActiveFilterClick(activeFilter);
            }, 'activefiltersclick');
        }
    }

    onActiveFilterClick(activeFilter) {
        jQuery('.PM_ASCriterionsGroup').removeClass('active');

        this.showFilters();

        const filterID = activeFilter.dataset.filter;
        const filter = document.getElementById(filterID);

        filter.classList.add('active');
    }

    initShowResults() {
        const showButtons = document.querySelectorAll('.js-filters-select, .filters-show-results');

        for (const button of showButtons) {
            Listener.addOnce(button, 'click', () => {
                this.hideAllGroups();
                this.submitFilters();
            }, 'showbuttonclick');
        }
    }

    initResetFilters() {
        const resetFilters = document.querySelectorAll('.custom-filters .filters-clear');

        const checkedFilters = document.querySelectorAll('.PM_ASCriterionsGroup.checked');

        for (const resetFiltersButton of resetFilters) {
            Listener.addOnce(resetFiltersButton, 'click', () => {
                this.onResetFiltersButtonClick();
            }, 'resetfiltersclick');

            resetFiltersButton.style.display = !checkedFilters.length ? "none" : "inline-block";
        }
    }

    onResetFiltersButtonClick() {
        const resetFiltersNative = document.querySelector('.PM_ASResetSearch');

        if (resetFiltersNative) {
            resetFiltersNative.click();
        }
    }

    initResetGroup() {
        const resetLinks = document.querySelectorAll('.PM_ASSelectionsRemoveLink');
        for (const resetLink of resetLinks) {
            Listener.addOnce(resetLink, 'click', (e) => {
                return this.onResetLinkClick(e, resetLink);
            }, 'resetlinkclick');
        }
    }

    onResetLinkClick(e, resetLink) {
        e.preventDefault();
        e.stopPropagation();

        this.resetInputByResetLink(resetLink);
        return false; // prevent native reset
    }
    // SETUP

    updateFilters() {
        const filters = document.querySelectorAll('.PM_ASCriterionsGroup');

        for (const filter of filters) {
            this.updateFilter(filter);
        }
    }

    updateFilter(filter) {
        if (!filter) {
            return;
        }

        const inputsChecked = filter.querySelectorAll('input[type=checkbox]:checked');
        filter.classList.toggle('checked', inputsChecked.length > 0);
        filter.classList.toggle('empty', inputsChecked.length === 0);
    }

    // HELPERS

    resetInputByResetLink(resetLink) {
        const resetInput = resetLink.nextElementSibling;

        if (!resetInput) {
            return;
        }

        const criterionValue = resetInput.value;
        const criterionInput = document.querySelector(`.PM_ASCriterionsGroup input[value="${criterionValue}"]`);

        if (criterionInput) {
            criterionInput.checked = false;
        }

        this.submitFilters();
    }

    submitFilters() {
        const idSearch = this.getIdSearch();

        if (idSearch) {
            arkon_run_search(idSearch);
        }
    }

    getActiveFilterTemplate(filter) {
        if (!filter) {
            return ``;
        }

        const filterID = filter.id;
        const filterName = filter.querySelector('.PM_ASCriterionsGroupName');

        const inputsChecked = filter.querySelectorAll('input[type=checkbox]:checked');
        const countClass = inputsChecked.length ? "active" : "empty";

        if (!filterName || !filterID) {
            return null;
        }

        return `<span class="js-active-filter" data-filter='${filterID}'>
            ${filterName.innerHTML}
            <span class="js-active-filter__count ${countClass}">${inputsChecked.length}</span>
        </span>`;
    }

    getIdSearch() {
        const search = document.querySelector('.PM_ASBlockOutput');

        return search.dataset.idSearch;
    }

    showFilters() {
        const filters = document.querySelector('.custom-filters');

        if (filters) {
            filters.classList.add('active');
        }
    }

    hideFilters() {
        const filters = document.querySelector('.custom-filters');

        if (filters) {
            filters.classList.remove('active');
        }
    }

    hideAllGroups() {
        const groups = document.querySelectorAll('.PM_ASCriterionsGroup');

        for (const group of groups) {
            group.classList.remove('active');
        }
    }
}