export default class Listener {
    static addOnce(element, event, callback, name) {
        const eventName = `data-event-${name}`;

        if(!element.hasAttribute(eventName)) {
            element.addEventListener(event, callback);
            element.setAttribute(eventName, true);
        }
    }
}
